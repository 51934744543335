.layout {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.layout > .header {
  flex: 1 0 auto;
}

.layout > .content {
  width: 98.5%;
}
